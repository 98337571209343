import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/tickets-soporte`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(idTicket, sesion) {
    return apiClient.post(`${idTicket}/sesiones/${sesion.id}`, sesion)
  },
  iniciar:function(idTicket) {
    return apiClient.post(`${idTicket}/sesiones/iniciar`)
  },
  pausar:function(idTicket, sesion) {
    return apiClient.post(`${idTicket}/sesiones/pausar`, sesion)
  },
  reanudar:function(idTicket) {
    return apiClient.post(`${idTicket}/sesiones/reanudar`)
  },
  finalizar:function(idTicket, datos) {
    return apiClient.post(`${idTicket}/sesiones/finalizar`, datos)
  },





  eliminar(idTicket, idSesion) {
    return apiClient.delete(`${idSesion}`)
  },

  guardar(idTicket, sesion) {
    return apiClient.post('', sesion)
  },

  sesionJSON(idTicket, idSesion) {
    return apiClient.get(`${id}.json`)
  },

  sesionesJSON(idTicket, params) {
    params = params || {}
    return apiClient.get(`${idTicket}/sesiones.json`, { params: params })
  }
}
